//import manager from '@/utils/manager'
import api from '@/utils/api'

export function payWin(params) {
    return api.post('winPay/pay', params)
}

export function countryList(params) {
    return api.get('winPay/getCountryList', params)
}

export function getUrlKey(name) {
    // eslint-disable-next-line no-sparse-arrays
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

