<template>
  <div>
    <!--测试引入js-->
<!--    <remote-js cdn="https://stage-js.wintopay.com/js/shield"></remote-js>-->
    <remote-js cdn="https://js.win4mall.com/js/shield"></remote-js>
    <span>
       <p style="font-size: 30px;font-weight:bold; text-align: center">Pay Info</p>
    </span>
    <div style="height: 80px;display: flex;flex-direction: row;justify-content: center;align-items:center;">
      <van-image
          fit="cover"
          position="left"
          :src="require('@/assets/winPay/img/1221.png')"
      />
<!--      <van-image-->
<!--          class="pay_icon"-->
<!--          fit="cover"-->
<!--          position="left"-->
<!--          :src="require('@/assets/winPay/img/2.png')"-->
<!--      />-->
<!--      <van-image-->
<!--          class="pay_icon"-->
<!--          fit="cover"-->
<!--          position="center"-->
<!--          :src="require('@/assets/winPay/img/3.png')"-->
<!--      />-->
<!--      <van-image-->
<!--          class="pay_icon"-->
<!--          fit="cover"-->
<!--          position="right"-->
<!--          :src="require('@/assets/winPay/img/4.png')"-->
<!--      />-->
<!--      <van-image-->
<!--          class="pay_icon"-->
<!--          fit="cover"-->
<!--          position="right"-->
<!--          :src="require('@/assets/winPay/img/5.png')"-->
<!--      />-->
    </div>
    <van-form @submit="onSubmit">
      <!--账号-->
      <van-field
          v-model="car_num"
          name="car_num"
          label="Card number"
          placeholder="输入您的卡号"
          :rules="[
          { required: true, message: '请输入卡号'},
        ]"
      >
        <van-button
            v-show="carNumList.length > 0"
            slot="button"
            size="small"
            type="primary"
            @click="carNumShowPicker = true"
            native-type="button"
        >历史账号</van-button
        >
      </van-field>
      <van-popup v-model="carNumShowPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="carNumList"
            @confirm="accountConfirm"
            @cancel="carNumShowPicker = false"
        />
      </van-popup>
      <!--年份-->
      <van-field
          readonly
          clickable
          name="year"
          :value="yearText"
          label="Year"
          placeholder="选择年份"
          @click="yearShowPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="yearShowPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="yearList"
            @confirm="yearConfirm"
            @cancel="yearShowPicker = false"
        />
      </van-popup>
      <!--月份-->
      <van-field
          readonly
          clickable
          name="month"
          :value="monthText"
          label="Month"
          placeholder="选择月份"
          @click="monthShowPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="monthShowPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="monthList"
            @confirm="monthConfirm"
            @cancel="monthShowPicker = false"
        />
      </van-popup>
      <!--cvv-->
      <van-field
          v-model="cvv"
          name="cvv"
          label="CVV"
          placeholder="cvv"
          :rules="[
          { required: true, message: '请输入cvv'},
        ]"
      >
      </van-field>
      <!--名字-->
      <van-field
          v-model="billing_first_name"
          name="billing_first_name"
          label="First name"
          placeholder="输入您的名字"
          :rules="[
          { required: true, message: '请输入您的名字'},
        ]"
      >
      </van-field>
      <!--姓氏-->
      <van-field
          v-model="billing_last_name"
          name="billing_last_name"
          label="Last name"
          placeholder="输入您的姓氏"
          :rules="[
          { required: true, message: '请输入您的姓'},
        ]"
      >
      </van-field>
      <!--电子邮箱-->
      <van-field
          v-model="billing_email"
          name="billing_email"
          label="Email"
          placeholder="输入您的邮箱账号"
          :rules="[
          { required: true, message: '请输入您的电子邮件'},
        ]"
      >
      </van-field>
      <!--手机号码-->
      <van-field
          v-model="billing_phone"
          name="billing_phone"
          label="Phone"
          placeholder="输入您的手机号"
          :rules="[
          { required: true, message: '请输入您的手机号'},
        ]"
      >
      </van-field>
      <!--国家-->
      <van-field
          readonly
          clickable
          name="shipping_country"
          :value="billing_country"
          label="Country"
          placeholder="选择国家"
          @click="countryShowPicker = true"
          :rules="[{ required: true }]"
      />
      <van-popup v-model="countryShowPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="countryList"
            @confirm="countryConfirm"
            @cancel="countryShowPicker = false"
        />
      </van-popup>

      <!--省/州-->
      <van-field
          v-model="billing_state"
          name="billing_state"
          label="State"
          placeholder="输入您的省/州"
          :rules="[
          { required: true, message: '请输入您的省/州'},
        ]"
      >
      </van-field>
      <!--城市-->
      <van-field
          v-model="billing_city"
          name="billing_city"
          label="City"
          placeholder="输入您的城市"
          :rules="[
          { required: true, message: '请输入您的城市'},
        ]"
      >
      </van-field>
      <!--邮政编码-->
      <van-field
          v-model="billing_postal_code"
          name="billing_postal_code"
          label="Postcode"
          placeholder="输入您的邮编"
          :rules="[
          { required: true, message: '请输入您的邮政编码'},
        ]"
      >
      </van-field>
      <!--详细地址-->
      <van-field
          v-model="billing_address"
          name="billing_address"
          label="Address"
          placeholder="输入您的地址"
          :rules="[
          { required: true, message: '请输入您的地址'},
        ]"
      >
      </van-field>

<!--      <div style="margin: 16px">-->
<!--        <van-button style="width: 45%;float:left" type="primary">支付</van-button>-->
<!--      </div>-->
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          Pay {{this.pay_money}} 💲
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

import {
  payWin,
  countryList,
  getUrlKey
} from "@/api/winPay";

import {Toast} from "vant";

export default {
  name: "winPayPage",
  components: {
    'remote-js': {
      render(createElement) {
        return createElement('script', {
          attrs: {
            type: 'text/javascript',
            src: this.cdn
          }
        })
      },
      props: {
        cdn:  {
          type: String,
          required: true
        }
      }
    },
  },

  data() {
    return {
      pay_money: "",
      car_num: "",
      cvv: "",
      billing_first_name:"",
      billing_last_name:"",
      billing_email:"",
      billing_phone:"",
      billing_country:"",
      countryList:[],
      countryShowPicker: false,
      billing_state:"",
      billing_city:"",
      billing_address:"",
      billing_postal_code:"",
      carNumList:[],
      carNumShowPicker: false,
      yearText:"",
      monthText:"",
      yearList: [
        2017,
        2018,
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030,
        2031,
        2032,
        2033,
        2034,
      ],
      monthList: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12
      ],
      monthShowPicker:false,
      yearShowPicker:false,
    };
  },
  created() {
    console.log(localStorage.car_num_info);
    if (localStorage.car_num_info) {
      const car_num_info =
          typeof localStorage.car_num_info === "string"
              ? JSON.parse(localStorage.car_num_info)
              : localStorage.car_num_info;
      this.carNumList = Object.keys(car_num_info);
    }
  },
  //加载这个页面的时候执行
  mounted() {
    if (this.$route.params.money) {
      localStorage.setItem("pay_money_info", this.$route.params.money);
    }
    if (getUrlKey("money")) {
      localStorage.setItem("pay_money_info", getUrlKey("money"));
    }
    if (this.$route.params.order_num) {
      localStorage.setItem("order_num", this.$route.params.order_num);
    }
    if (getUrlKey("order_num")) {
      localStorage.setItem("order_num", getUrlKey("order_num"));
    }
    if (this.$route.params.user_id) {
      localStorage.setItem("user_id", this.$route.params.user_id);
    }
    if (getUrlKey("user_id")) {
      localStorage.setItem("user_id", getUrlKey("user_id"));
    }

    if (getUrlKey("is_h5")) {
      localStorage.setItem("is_h5", getUrlKey("is_h5"));
    } else {
      localStorage.setItem("is_h5", "0");
    }
    console.log(localStorage.getItem("pay_money_info"));
    this.pay_money = localStorage.getItem("pay_money_info");
    this.order_num = localStorage.getItem("order_num");
    this.user_id = localStorage.getItem("user_id");
    this.is_h5 = localStorage.getItem("is_h5");
    this.getCountryList();
    Toast.clear(true);
  },
  methods:{
    onSubmit() {
      Toast.loading({
        message: "支付中，请稍等!",
        forbidClick: true,
        duration: 0,
      });
      payWin({
        // order_num: this.$route.params.order_num ? this.$route.params.order_num : getUrlKey("order_num"),
        // user_id: this.$route.params.user_id ? this.$route.params.user_id : getUrlKey("user_id"),
        order_num: this.order_num,
        user_id: this.user_id,
        card_number:this.car_num,
        month:this.monthText,
        year:this.yearText,
        cvv:this.cvv,
        // session_id:wintopayShield.getSessionId(),
        billing_first_name:this.billing_first_name,
        billing_last_name:this.billing_last_name,
        billing_email:this.billing_email,
        billing_phone:this.billing_phone,
        billing_country:this.billing_country,
        billing_state:this.billing_state,
        billing_city:this.billing_city,
        billing_address:this.billing_address,
        billing_postal_code:this.billing_postal_code,
      }).then((response) => {
        console.log(response)
        Toast.clear();
        const message = this.is_h5 == 1 ? "您已支付成功，如再需充值请点击右上方关闭按钮重新充值噢" : "Pay Success !"
        this.$dialog.confirm({
          title: "Pay Success",
          message: message,
          showCancelButton:false,
          confirmButtonText: "确定", //改变确认按钮上显示的文字
          // cancelButtonText: "你坏" //改变取消按钮上显示的文字
        }).then(()=> {
          console.log("点击了确认按钮噢");
          this.$router.go(-1);
        }).catch(() => {
          console.log("点击了取消按钮噢")
        })
        // window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        console.log("is_h5的值:" + this.is_h5);
        if(this.is_h5 == 1) {
          this.$dialog.confirm({
            title: "Pay fail",
            message: "支付失败，请点击右上角关闭按钮重新支付！！！",
            showCancelButton:false,
            confirmButtonText: "确定", //改变确认按钮上显示的文字
            // cancelButtonText: "你坏" //改变取消按钮上显示的文字
          }).then(()=> {
            console.log("点击了确认按钮噢");
          }).catch(() => {
            console.log("点击了取消按钮噢")
          })
          // this.$toast.fail('支付失败，请点击右上角关闭按钮重新支付！！！');
        } else {
          this.$toast.fail('发起支付失败');
          const that  = this;
          setTimeout(function () {
            that.$router.go(-1);
          },3000);
        }
      })
      this.saveUserRole();
    },
    getCountryList() {
      countryList().then((response) => {
        console.log(response);
        this.countryList = response;
      })
          .catch(() => {});
    },
    countryConfirm(value) {
      this.billing_country = value.value;
      this.countryShowPicker = false;
    },
    yearConfirm(value) {
      this.yearText = value;
      this.yearShowPicker = false;
    },
    monthConfirm(value) {
      this.monthText = value;
      this.monthShowPicker = false;
    },
    // 保存用户信息
    saveUserRole() {
      const account_item = {
        card_number:this.car_num,
        month:this.monthText,
        year:this.yearText,
        cvv:this.cvv,
        billing_first_name:this.billing_first_name,
        billing_last_name:this.billing_last_name,
        billing_email:this.billing_email,
        billing_phone:this.billing_phone,
        billing_country:this.billing_country,
        billing_state:this.billing_state,
        billing_city:this.billing_city,
        billing_address:this.billing_address,
        billing_postal_code:this.billing_postal_code,
      };
      if (this.carNumList.indexOf(this.car_num) === -1) {
        this.carNumList.push(this.car_num)
      }
      if (localStorage.car_num_info) {
        let car_num_info =
            typeof localStorage.car_num_info === "string"
                ? JSON.parse(localStorage.car_num_info)
                : localStorage.car_num_info
        car_num_info[this.car_num] = account_item
        localStorage.car_num_info = JSON.stringify(car_num_info)
      } else {
        localStorage.car_num_info = JSON.stringify({
          [this.car_num]: account_item,
        })
      }
    },
    accountConfirm(value) {
      this.carNumShowPicker = false;
      if (localStorage.car_num_info) {
        const car_num_info =
            typeof localStorage.car_num_info === "string"
                ? JSON.parse(localStorage.car_num_info)
                : localStorage.car_num_info;
        if (car_num_info[value]) {
          const account_item = car_num_info[value];
          // 填充数据
          this.car_num = account_item.card_number
          this.monthText = account_item.month
          this.yearText = account_item.year
          this.cvv = account_item.cvv
          this.billing_first_name = account_item.billing_first_name
          this.billing_last_name = account_item.billing_last_name
          this.billing_email = account_item.billing_email
          this.billing_phone = account_item.billing_phone
          this.billing_country = account_item.billing_country
          this.billing_state = account_item.billing_state
          this.billing_city = account_item.billing_city
          this.billing_address = account_item.billing_address
          this.billing_postal_code = account_item.billing_postal_code
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
